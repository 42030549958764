import React from 'react';
import { navigate } from 'gatsby';

export default function Epo() {
  return (
    <section className="expertise-section" id="epo">
      <div className='container-fluid my-5'></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 content-section bg-grey order-0 py-lg-5">
            <h3 className="font-size__xl text-center mb-5">
              Ecommerce Performance Optimization
            </h3>
            <div className="row">
              <div className="col-9 mx-auto">
                <p className="font-size__m">
                Tras más de 15 años de experiencia, y gracias a todo el conocimiento acumulado, 
                hemos desarrollado el método EPO <i class="fa-solid fa-rocket"></i> para mejorar el rendimiento de tu eCommerce.
                </p>
              </div>
            </div>
            <h3 className="font-size__l text-center text-secondary mt-5">
            Con nuestro método conseguirás
            </h3>
            <div className="row text-center">
              <div className="col-9 mx-auto">
                <p className="font-size__m">
                  <ul className='mb-5 text-left list-unstyled'>
                      <li><i class="fa-solid fa-check"></i> Acelerar el tiempo de carga de tu eCommerce</li>
                      <li><i class="fa-solid fa-check"></i> Agilizar la integración con tu ERP, PIM o CRM</li>
                      <li><i class="fa-solid fa-check"></i> Eliminar problemas de rendimiento</li>
                      <li><i class="fa-solid fa-check"></i> Reducir el tiempo y los costes de ejecución implementando nuestros propios desarrollos</li>
                  </ul>
                </p>
                <button className='btn btn-primary-green btn-lg mb-5 mb-lg-0' onClick={() => {navigate("/contacto/")}}>Quiero saber más</button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 epo-bg order-1 pb-0 py-lg-5">
          </div>
        </div>
      </div>
    </section>
  );
}
