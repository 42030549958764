import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

export default function Solutions() {
  return (
    <>
      <section
        className="content-section home-solutions text-white bg-white"
        id="services"
      >
        <h3 className='text-center mb-4 text-secondary font-size__xl px-3'>Soluciones Ecommerce para B2C, B2B y Marketplace</h3>
        <h4 className='text-center mb-5 text-secondary font-size__m h4 px-3'>Cada tipo de negocio tiene sus pecualiaridades y complejidad.<br />
            Por suerte, existen diferentes soluciones ecommerce para cubrir los requerimientos de cada proyecto.
        </h4>
        <div className="">
          <div className="row no-gutters">
            <div className="col-12 col-lg-4">
              <div className="solution-block b2b py-5">
                <i className="fas fa-cart-shopping text-white"></i>
                <h2 className="mt-4 text-primary">
                  Ecommerce B2B
                </h2>
                <p className='font-size__m mb-3'>Para empresas que venden a otras empresas</p>
                <button class="learn-more" onClick={() => {navigate("soluciones-ecommerce-b2b/")}}>
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Más Información</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="solution-block b2c py-5">
                <i className="fas fa-basket-shopping text-white"></i>
                <h2 className="mt-4 text-secondary">
                    Ecommerce B2C
                </h2>
                <p className='font-size__m mb-3'>Para empresas que venden a <br />particulares</p>
                <button class="learn-more" onClick={() => {navigate("soluciones-ecommerce-b2c/")}}>
                  <span class="circle secondary" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text secondary">Más Información</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="solution-block marketplace py-5">
                <i class="fa-solid fa-store text-white"></i>
                <h2 className="mt-4 text-primary">
                    Marketplace
                </h2>
                <p className='font-size__m mb-3'>Para empresas que quieren aumentar sus oportunidades de negocio</p>
                <button class="learn-more" onClick={() => {navigate("/marketplace-ecommerce/")}}>
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Más Información</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
