import React from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';

export default function Services() {
  return (
    <>
      <section
        className="content-section home-services text-white"
        id="services"
      >
        <h3 className='text-center mb-5 text-secondary font-size__xl'>¿Qué necesita tu ecommerce?</h3>
        <div className="">
          <div className="row no-gutters">
            <div className="col-xs-12 col-lg-4">
              <div className="service-block consultancy py-5">
              <i className="far fa-lightbulb fa-5x"></i>
                <h2 className="mt-5 p-md-3 text-primary">
                  Estrategia
                </h2>
                <p className='mb-3'>
                  Tanto si empiezas de cero como si ya dispones de una plataforma que no cumple tus expectativas
                </p>
                <button class="learn-more" onClick={() => {navigate("servicios/#estrategia")}}>
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Más Información</span>
                </button>
              </div>
            </div>
            <div className="col-xs-12 col-lg-4">
              <div className="service-block development py-5">
                <i className="fas fa-terminal fa-5x"></i>
                <h2 className="mt-5 p-md-3 text-primary">
                  Desarrollo ecommerce
                </h2>
                <p className='mb-3'>
                  Nos ocupamos de todo:<br />Desarrollo, migración de datos, diseño, integración ERP
                </p>
                <button class="learn-more" onClick={() => {navigate("servicios/#desarrollo")}}>
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Más Información</span>
                </button>              
              </div>
            </div>
            <div className="col-xs-12 col-lg-4">
              <div className="service-block support py-5">
                <i className="far fa-life-ring fa-5x"></i>
                <h2 className="mt-5 p-md-3 text-primary">
                  Soporte y Mantenimiento
                </h2>
                <p className="mb-3">
                  Tanto si eres una agencia como una empresa que necesita ayuda con un proyecto Magento
                </p>
                <button class="learn-more" onClick={() => {navigate("servicios/#soporte")}}>
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Más información</span>
                </button>              
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
