import React from 'react';
import { navigate } from 'gatsby';

export default function Contacta() {
  return (
    <section className="contacta-section" id="contacta">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 content-section bg-grey-dark">
            <h3 className="font-size__xl text-center mb-5 text-secondary">
              Deja tu ecommerce en manos expertas
            </h3>
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-8 px-4 px-md-5 px-lg-4 px-xl-5 mx-auto">
                <p className="font-size__m">
                Puede que estés pensando en lanzar un ecommerce, que tu plataforma actual ya no sea suficiente o que necesites 
                un profesional para desencallar ese proyecto que tanto se resiste.
                </p>
                <p className="font-size__m">
                 Sea lo que sea, nos hacemos cargo y respondemos con rapidez y eficiencia. Porque siempre hay más opciones.
                </p>
                <p className='font-size__m'>
                  Y nos encantaría que nos dieras la oportunidad de demostrártelo.
                </p>
                <div className='text-center mx-auto mt-5'>
                  <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>Contacta con nosotros</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
