import React from 'react';

import LayoutEs from '../components/LayoutEs';
import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import About from '../components/About';
import Services from '../components/Services';
import Epo from '../components/Epo';
import SEO from '../components/Seo';
import Contacta from '../components/Contacta';
import Projects from '../components/Proyectos';
import { navigate } from "gatsby"
import ScrollToTop from '../components/ScrollToTop';
import Solutions from '../components/Solutions';

const IndexPage = () => (
  <LayoutEs>
    <Header />
    <SEO title="Expertos ecommerce Barcelona"canonicalUrl="https://www.bekome.digital"/>
    <SEO description={
      "Especialistas en desarrollo ecommerce Magento en Barcelona. Desarrollamos tu tienda online Magento y BigCommerce. Somos desarrolladores certificados"}
    />
    <header className="home-hero-banner">
      <div className='home-hero-video'>
        <video className='' id='video-element' loop autoPlay playsInline muted>
          <source src="https://res.cloudinary.com/drjo0cbtf/video/upload/ac_none,f_auto,q_auto,vc_auto/v1670272337/bekome/especialistas-ecommerce_cxrt0f.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="container text-center my-auto home-hero-content">
        <h1 className="mb-4 text-primary">{config.heading_es_ES}</h1>
        <h2 className="mb-0">
          {config.subHeading_es_ES}
        </h2>
        <p className='h2 mb-5'>
          que buscan mejorar los <strong className='text-primary'>resultados</strong> de negocio, 
          minimizar los <strong className='text-primary'>costes</strong> de mantenimiento y tratar con&nbsp;
          <strong className='text-primary'>profesionales</strong> que van un paso por delante
        </p>

        <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>¿Hablamos?</button>
      </div>
      <div className="overlay"></div>
    </header>

    <About />

    <Services />

    <Epo />

    <Solutions />

    <Projects />

    <Contacta />

    <Footer />

    <ScrollToTop />
  </LayoutEs>
);

export default IndexPage;
