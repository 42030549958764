import React from 'react';
import { navigate } from "gatsby"

export default function About() {
  return (
    <section className="content-section about-section" id="about">
      <div className="container mt-lg-4">
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="block-gradient__main">
              <h2 className="text-white mb-lg-5 font-size__l text-center">
                Tu agencia Magento en Barcelona
              </h2>
              <div className="row">
                <div className="col-lg-6 col-12">
                  <p className="text-white mb-lg-5 font-size__m">
                    Somos una de las pocas agencias españolas que cuenta con desarrolladores certificados Magento y BigCommerce.
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="text-center">
                    <p className="text-white text-left mb-4 font-size__m">
                      Esta certificación avala nuestro conocimiento sobre la plataforma y nos posiciona como agencia experta en Magento
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <p className="text-white mb-lg-5 font-size__m">
                    Sin embargo, nuestro afán por asumir nuevos retos y encontrar la solución que mejor se ajuste a cada 
                    ecommerce, nos ha llevado a declararnos 'platform agnostics'.
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <div className='mb-5'>
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.7/v1569233073/bekome/magento_associate_developer_kitmg6.png"
                      alt="Magento Certified Associate Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.7/v1569233073/bekome/magento_frontend_developer_cgc0pj.png"
                      style={{ margin: '0 4px 0 0' }}
                      alt="Magento Frontend Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.5/v1569233073/bekome/magento_professional_developer_pjiatr.png"
                      style={{ margin: '0 8px 0 0' }}
                      alt="Magento Professional Developer"
                      className="img-fluid"
                    />
                    <img
                      src="https://res.cloudinary.com/drjo0cbtf/image/upload/w_0.5/v1569233073/bekome/magento_solution_specialist_xklxri.png"
                      alt="Magento Solution Specialist"
                      className="img-fluid"
                    />
                  </div>
                  <div className='text-center'>
                    <button className='btn btn-primary btn-lg' onClick={() => {navigate("/nosotros/")}}>Conócenos mejor</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
